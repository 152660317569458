<template>
  <b-modal
    id="failed-payment-behavior-settings-modal"
    ref="failed-payment-behavior-settings-modal"
    centered
    title="Application Failed Payment Behavior Settings"
    ok-title="Confirm"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="setModalData"
    @ok="handleForm"
    @hidden="initAppPaymentFailedSettingsData"
  >
    <validation-observer
      ref="appPaymentFailedSettingsObserver"
      tag="form"
    >
      <b-row>
        <!--Allow to Move Applications Forward-->
        <b-col
          cols="12"
          class="d-flex align-items-center mb-2"
        >
          <b-form-checkbox
            v-model="appPaymentFailedSettingsData.allow_move_forward"
            switch
            class="d-inline-block ml-50 mr-1"
            :disabled="appPaymentFailedSettingsData.move_to_status"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
          <label
            class="mr-1 mb-0 col-form-label"
          >
            Allow applications to move forward to accepted statuses if the payment doesn't go through
          </label>
        </b-col>

        <!--Move Applications to Specific Status-->
        <b-col
          cols="12"
          class="d-flex align-items-center mb-2"
        >
          <b-form-checkbox
            v-model="appPaymentFailedSettingsData.move_to_status"
            switch
            class="d-inline-block ml-50 mr-1"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
          <label
            class="mr-1 mb-0 col-form-label"
          >
            Move applications to specific status if the payment doesn’t go through
          </label>
        </b-col>

        <!--Selection of app status-->
        <b-col
          v-if="appPaymentFailedSettingsData.move_to_status"
          cols="12"
        >
          <b-form-group
            label="Select status for application with failed payment"
          >
            <validation-provider
              #default="{ errors }"
              name="Application Statuses"
              rules="required"
            >
              <v-select
                v-model="appPaymentFailedSettingsData.specific_status"
                :options="statusesList"
                label="name"
                value="id"
                :clearable="false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Select Status"
                :reduce="option => option.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="appPaymentFailedSettingsData.move_to_status || !appPaymentFailedSettingsData.allow_move_forward"
          cols="12"
          class="d-flex align-items-center mb-2"
        >
          <b-form-checkbox
            v-model="appPaymentFailedSettingsData.move_forward_after_successful_payment"
            switch
            class="d-inline-block ml-50 mr-1"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
          <label
            class="mr-1 mb-0 col-form-label"
          >
            To continue application status flow in case of successful payment
          </label>
        </b-col>

      </b-row>

    </validation-observer>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,

    vSelect,
  },
  props: {
    statusesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      appPaymentFailedSettingsData: {},
      required,
    }
  },
  watch: {
    'appPaymentFailedSettingsData.move_to_status': function fn(val) {
      this.$nextTick(() => {
        if (val) {
          this.appPaymentFailedSettingsData.allow_move_forward = false
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      appPaymentFailedSettings: 'app-program/getFailedPaymentBehaviorSettings',
    }),
  },
  methods: {
    setModalData() {
      this.initAppPaymentFailedSettingsData()
    },
    initAppPaymentFailedSettingsData() {
      this.appPaymentFailedSettingsData = {
        allow_move_forward: this.appPaymentFailedSettings.allow_move_forward ?? true,
        move_to_status: this.appPaymentFailedSettings.move_to_status ?? false,
        specific_status: this.appPaymentFailedSettings.specific_status ?? null,
        move_forward_after_successful_payment: this.appPaymentFailedSettings.move_forward_after_successful_payment ?? false,
      }
    },
    handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.appPaymentFailedSettingsObserver.validate().then(success => {
          if (success) {
            resolve(true)
            this.saveData()
          } else {
            reject()
          }
        })
      })
    },
    async saveData() {
      store.commit('app-program/SET_FAILED_PAYMENT_BEHAVIOR_SETTINGS', this.appPaymentFailedSettingsData)
      store.commit('app-program/SET_HAS_UNSAVED_CHANGES', true)
      this.$bvModal.hide('failed-payment-behavior-settings-modal')
    },
  },
}
</script>
