var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"setup-status-events",attrs:{"id":"setup-status-events","centered":"","size":"xl","title":"Set up status event","ok-title":"Save events","cancel-variant":"outline-secondary","no-close-on-backdrop":"","ok-disabled":!_vm.canUpdateApplicationTriggers},on:{"show":_vm.setModalData,"ok":_vm.postStatusTriggers,"hidden":_vm.resetModalData}},[_c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.isLoading,"variant":"white","opacity":"1","rounded":"sm"}},[_c('validation-observer',{ref:"setupStatusEvents",attrs:{"tag":"form"}},[_vm._l((_vm.filteredTriggers),function(triggerRow,index){return [_c('b-row',{key:("trigger-row-" + index),staticClass:"border-bottom my-2"},[_c('b-col',{attrs:{"cols":"11"}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Status From"}},[_c('validation-provider',{attrs:{"name":"Status From","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"disabled":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusesList,"placeholder":"Select Status","clearable":false,"value":"id","label":"name","reduce":function (value) { return value.id; }},model:{value:(triggerRow.for_id),callback:function ($$v) {_vm.$set(triggerRow, "for_id", $$v)},expression:"triggerRow.for_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Status To"}},[_c('validation-provider',{attrs:{"name":"Status To","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusesList,"placeholder":"Select Status","clearable":false,"value":"id","label":"name","reduce":function (value) { return value.id; }},model:{value:(triggerRow.to_id),callback:function ($$v) {_vm.$set(triggerRow, "to_id", $$v)},expression:"triggerRow.to_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Trigger"}},[_c('validation-provider',{attrs:{"name":"trigger","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.triggersOptions,"placeholder":'Select Trigger',"clearable":false,"reduce":function (value) { return value.value; }},model:{value:(triggerRow.trigger),callback:function ($$v) {_vm.$set(triggerRow, "trigger", $$v)},expression:"triggerRow.trigger"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(triggerRow.trigger === 'Email' || triggerRow.trigger == null)?_c('b-col',{staticClass:"mb-1",attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Email Template"}},[_c('validation-provider',{attrs:{"name":"email template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.emailTemplatesOptions,"clearable":false,"placeholder":'Select Email Template',"value":"id","label":"name","reduce":function (val) { return val.id; }},model:{value:(triggerRow.email_template_id),callback:function ($$v) {_vm.$set(triggerRow, "email_template_id", $$v)},expression:"triggerRow.email_template_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_c('b-col',{staticClass:"mb-1",attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Text"}},[_c('validation-provider',{attrs:{"name":"text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"id":"email-content"},model:{value:(triggerRow.message_text),callback:function ($$v) {_vm.$set(triggerRow, "message_text", $$v)},expression:"triggerRow.message_text"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-col',{staticClass:"align-self-center",attrs:{"cols":"1"}},[(_vm.canDeleteApplicationTriggers)?_c('SquareIcon',{staticClass:"cursor-pointer",attrs:{"size":"21","icon":"Trash2Icon"},on:{"onIconClick":function($event){return _vm.removeRow(triggerRow.frontID)}}}):_vm._e()],1)],1)]}),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-button',{staticClass:"d-flex align-items-center justify-content-center w-100 mt-2",attrs:{"variant":"outline-primary","disabled":!_vm.canCreateApplicationTriggers},on:{"click":_vm.addNewTriggerRow}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon","size":"18"}}),_vm._v(" Add Trigger ")],1)],1)],1)],2),(_vm.validationErrors.length)?_c('div',{staticClass:"form-group"},_vm._l((_vm.validationErrors),function(validationError,index){return _c('p',{key:index,staticClass:"text-center text-danger"},[_vm._v(" "+_vm._s(validationError)+" ")])}),0):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }