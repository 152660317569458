<template>
  <div>
    <b-card no-body>
      <b-tabs
        v-model="tabIndex"
        content-class="col-12 mt-1 mt-md-0"
        pills
        fill
        nav-wrapper-class="col-12 py-1 px-0 border-bottom  bg-white"
        nav-class="mb-0 bg-light text-center"
        :disabled="isLoading"
      >

        <b-tab>
          <template #title>
            <span class="font-weight-bold">General</span>
          </template>
          <ProgramTabMain
            :user-data="options.main"
            :payment-plan="options.paymentPlan"
            :payment-contract="paymentContract"
            :payment-contracts-list="paymentContractsList"
            @nextTab="completeGeneralSettings"
          />
        </b-tab>

        <b-tab
          :disabled="!isGeneralSettingsCompleted"
        >
          <template #title>
            <span class="font-weight-bold">{{ sessionSemester(2) }}</span>
          </template>
          <ProgramTabSessions
            :sessions="options.sessions"
            :enable-dates="options.main.date"
            :program-data="options.main"
            @nextTab="completeSessionsSettings"
          />
        </b-tab>

        <b-tab
          :disabled="!isSessionsSettingsCompleted"
        >
          <template #title>
            <span class="font-weight-bold">Communications</span>
          </template>
          <ProgramTabCommunication
            :program-id="programID"
            :user-data="options.communication"
            :communication-settings-list="options.communicationSettingsList"
            @createProgram="saveChanges"
          />
        </b-tab>

        <b-tab
          lazy
          :disabled="!programID"
        >
          <template #title>
            <span class="font-weight-bold">Registration Statuses ({{ camperStudent(2) }})</span>
          </template>

          <ProgramTabRegistrationStatuses
            form-type="Camper"
          />
        </b-tab>

        <b-tab
          lazy
          :disabled="!programID"
        >
          <template #title>
            <span class="font-weight-bold">Registration Statuses (Staff)</span>
          </template>

          <ProgramTabRegistrationStatuses
            form-type="Staff"
          />
        </b-tab>

        <b-tab
          :disabled="!programID"
        >
          <template #title>
            <span class="font-weight-bold">Tasks</span>
          </template>
          <ProgramTabTasks
            :program-id="programID"
          />
        </b-tab>

        <b-tab
          :disabled="!programID"
        >
          <template #title>
            <span class="font-weight-bold">Fees</span>
          </template>
          <ProgramTabFees
            v-if="programID"
            :program-id="programID"
          />
        </b-tab>

        <b-tab
          :disabled="!programID"
        >
          <template #title>
            <span class="font-weight-bold">Wallets</span>
          </template>
          <ProgramTabWallets
            v-if="programID"
            :program-id="programID"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'

import { setActiveTab } from '@core/mixins/setActiveTab'
import ProgramTabMain from '@/views/admin/program/program-tabs/ProgramTabMain.vue'
import ProgramTabSessions from '@/views/admin/program/program-tabs/ProgramTabSessions.vue'
import ProgramTabCommunication from '@/views/admin/program/program-tabs/ProgramTabCommunication.vue'
import ProgramTabRegistrationStatuses from '@/views/admin/program/program-tabs/ProgramTabRegistrationStatuses.vue'
import ProgramTabTasks from '@/views/admin/program/program-tabs/ProgramTabTasks.vue'
import ProgramTabFees from '@/views/admin/program/program-tabs/ProgramTabFees.vue'
import ProgramTabWallets from '@/views/admin/program/program-tabs/ProgramTabWallets.vue'

import store from '@/store'
import storeModule from '@/views/admin/program/programStoreModule'
import useProgramsSetting from '@/views/admin/program/useProgramsSetting'
import usePaymentContractsSetting from '@/views/admin/payment-contracts/usePaymentContractsSetting'

export default {
  components: {
    ProgramTabMain,
    ProgramTabSessions,
    ProgramTabCommunication,
    ProgramTabRegistrationStatuses,
    ProgramTabTasks,
    ProgramTabFees,
    ProgramTabWallets,
    BTabs,
    BTab,
    BCard,
  },
  mixins: [setActiveTab],
  data() {
    return {
      tabIndex: 0,
      isGeneralSettingsCompleted: false,
      isSessionsSettingsCompleted: false,
      camperFormID: null,
      staffFormID: null,
      programID: null,
      groupData: null,
      setProgram: null,
      options: {
        main: {
          avatar: null,
          title: null,
          prefix: null,
          date: null,
          description: null,
          capacity: null,
          gender: null,
          minAge: null,
          maxAge: null,
          costForAllSessions: null,
          virtualProgram: true,
          hidePrice: true,
          gallery: [],
          role: 'parent',
          limitSessionSelection: false,
          complete_registration_btn_text: 'Complete Registration',
        },
        sessions: [
          {
            name: '',
            date: '',
            price: '',
            registration: false,
            delete: false,
          },
        ],
        paymentPlan: {
          id: null,
          max_months: 0,
          is_enable: false,
        },
        communication: {
          email: null,
          name: null,
        },
        communicationSettingsList: [],
      },
      paymentContract: {
        id: null,
      },
      paymentContractsList: [],
    }
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.getters['app-program/getIsLoading']
      },
      set(val) {
        this.$store.commit('app-program/SET_IS_LOADING', val)
      },
    },
  },
  watch: {
    tabIndex(val) {
      this.setTabIndexQuery(val)
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-program'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      postProgram,
      updProgram,
      fetchProgram,
      fetchForms,
      saveCommunicationSetting,
      postPaymentPlan,
      updatePaymentPlan,
      updatePaymentContract,
    } = useProgramsSetting()

    const {
      fetchPaymentContractList,
    } = usePaymentContractsSetting()

    return {
      postProgram,
      updProgram,
      fetchProgram,
      fetchForms,
      saveCommunicationSetting,
      postPaymentPlan,
      updatePaymentPlan,
      fetchPaymentContractList,
      updatePaymentContract,
    }
  },
  async created() {
    await this.fetchAllForms()
    await this.fetchStatementTemplates()

    await this.preparedCommunicationSettings()
    this.paymentContractsList = await this.fetchPaymentContractList()
  },
  mounted() {
    this.setActiveTab()
  },
  destroyed() {
    store.commit('app-program/SET_PROGRAM_ID', null)
  },
  methods: {
    async fetchAllForms() {
      const response = await this.$store.dispatch('app-program/fetchForms')
      store.commit('app-program/SET_FORMS_LIST', response.data.data)
    },
    async fetchStatementTemplates() {
      const response = await this.$store.dispatch('app-program/fetchStatementTemplates')
      store.commit('app-program/SET_STATEMENT_TEMPLATES_LIST', response.data.data)
    },
    async preparedCommunicationSettings() {
      const rolesList = await this.$store.dispatch('app-program/fetchRolesList')
        .then(response => response.data.data.filter(item => item.name === 'staff' || item.name === 'parent'))

      const eventsList = await this.$store.dispatch('app-program/fetchEventsList', { type: 'program' })
        .then(response => response.data.data)

      eventsList.forEach(event => {
        rolesList.forEach(role => {
          this.options.communicationSettingsList.push({ role, userEvent: event, emailTemplate: { id: null } })
        })
      })
    },
    async saveChanges() {
      this.isLoading = true
      const [dateStart, , dateEnd] = (this.options.main.date).split(' ')

      const postProgram = {
        id: this.setProgram?.id,
        name: this.options.main.title,
        prefix: this.options.main.prefix,
        logoUploaded: this.options.main?.logoUploaded,
        gallery: this.options.main?.updatedGallery,
        date_start: dateStart,
        date_end: dateEnd === undefined ? dateStart : dateEnd,
        capacity: this.options.main.capacity,
        gender: this.options.main.gender,
        applicable_role: this.options.main.role,
        age_range_from: this.options.main.minAge,
        age_range_to: this.options.main.maxAge,
        is_virtual_program: this.options.main.virtualProgram,
        is_active: this.options.main.enableRegistration,
        is_hide_price: this.options.main.hidePrice,
        is_only_one_session_selection_allowed: this.options.main.limitSessionSelection,
        communication_settings_from_name: this.options.communication.name,
        communication_settings_from_email: this.options.communication.email,
        description: this.options.main.description,
        complete_registration_btn_text: this.options.main.complete_registration_btn_text,
        sessions: this.options.sessions.map(session => ({
          ...session,
          registration: !session.registration,
        })),
      }

      if (postProgram.id === undefined) {
        await this.createProgram(postProgram)
      } else {
        if (this.options.communication.id) {
          postProgram.communication_settings_id = this.options.communication.id
        }

        this.groupData = await this.updProgram(postProgram)
      }

      await this.savePaymentPlan()
      await this.savePaymentContract()

      this.isLoading = false
    },
    async createProgram(postProgram) {
      this.setProgram = await this.postProgram(postProgram)
      this.groupData = this.setProgram?.group_id
      this.programID = this.setProgram?.id

      store.commit('app-program/SET_PROGRAM_ID', this.programID)

      if (this.setProgram?.payment_plan) {
        this.options.paymentPlan.id = this.setProgram?.payment_plan?.id
      }

      if (this.setProgram.id !== undefined) {
        await this.createCommunicationsSettings(this.setProgram.id)
        this.tabIndex += 1
      }
    },
    async createCommunicationsSettings(programID) {
      const formData = this.options.communicationSettingsList
        .filter(settings => settings.emailTemplate.id)
        .map(settings => ({
          role_id: settings.role.id,
          email_template_id: settings.emailTemplate.id,
          user_event_id: settings.userEvent.id,
          program_id: programID,
        }))
      this.saveCommunicationSetting(formData)
    },
    async createdCamperForm(formId) {
      this.camperFormID = formId
    },
    async createdStaffForm(formId) {
      this.staffFormID = formId
    },
    async savePaymentPlan() {
      this.options.paymentPlan.program_id = this.setProgram.id

      if (this.options.paymentPlan.id) {
        this.updatePaymentPlan(this.options.paymentPlan)
      } else {
        this.postPaymentPlan(this.options.paymentPlan)
      }
    },
    async savePaymentContract() {
      const queryParams = {
        programId: this.programID,
        paymentContractId: this.paymentContract.id || '',
      }
      await this.updatePaymentContract(queryParams)
    },
    completeGeneralSettings() {
      this.isGeneralSettingsCompleted = true
      this.$nextTick(() => {
        this.tabIndex += 1
      })
    },
    completeSessionsSettings() {
      this.isSessionsSettingsCompleted = true
      this.$nextTick(() => {
        this.tabIndex += 1
      })
    },
  },
}
</script>
