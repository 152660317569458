<template>
  <b-card>
    <validation-observer
      ref="userRules"
      tag="form"
    >
      <!-- form -->
      <b-form>
        <b-row>

          <!-- title -->
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <h3 class="mb-0 font-weight-normal">
                Program {{ sessionSemester(2) }}
              </h3>
              <template>
                <b-form-checkbox
                  switch
                  v-model="programData.limitSessionSelection"
                  class="d-inline-block ml-4 mr-1"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
                <label
                  class="mb-0 col-form-label"
                >Limit to one session selection
                </label>
              </template>
            </div>
          </b-col>

        </b-row>

        <draggable
          :list="sessions"
          :group="{name: 'sessions'}"
          class="mb-1 cursor-move"
        >
          <b-row
            v-for="(session, index) in sessions"
            :key="index"
          >
            <template
              v-if="!session.delete"
            >
              <b-col
                md="11"
                xl="11"
              >
                <p
                  class="font-weight-bold"
                >{{ sessionSemester(1) }} {{ index + 1 }}</p>
                <b-row>

                  <b-col sm="6">
                    <b-form-group
                      :label="`${sessionSemester(1)} Name`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="session name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="session.name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                      :label="`${sessionSemester(1)} Dates`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="session dates"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="session.date"
                          class="form-control"
                          :class="{'is-invalid': errors.length > 0 }"
                          :config="{
                            mode: 'range',
                            enable: activeDates,
                        }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="!enableDates"
                          class="text-danger"
                        >Choose the program time frame first</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                      label="Price"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="session price"
                        rules="required|positive"
                      >
                        <b-form-input
                          v-model="session.price"
                          type="number"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                      label="Dependent Registration"
                    >
                      <b-form-checkbox
                        v-model="session.registration"
                      >
                        True
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="session.divisions && session.divisions.length"
                    sm="12"
                    class="mt-2"
                  >
                    <b-row>
                      <b-col sm="6">
                        <p
                          class="font-weight-bold"
                        >Division Name</p>
                      </b-col>

                      <b-col sm="6">
                        <p
                          class="font-weight-bold"
                        >Division Price ($)</p>
                      </b-col>
                    </b-row>
                    <template
                      v-for="(division, i) in session.divisions"
                    >
                      <b-row
                        :key="i"
                      >
                        <b-col sm="6">
                          <p>{{ division.name }}</p>
                        </b-col>

                        <b-col sm="6">
                          <b-form-input
                            v-model="division.price"
                            type="number"
                            class="mb-1"
                          />
                        </b-col>
                      </b-row>
                    </template>
                  </b-col>

                </b-row>
              </b-col>

              <!-- Input Delete -->
              <b-col
                md="1"
                xl="1"
                class="d-flex justify-content-end align-items-center"
              >
                <SquareIcon
                  v-if="sessions.filter(item => !item.delete).length > 1"
                  size="21"
                  icon="Trash2Icon"
                  class="cursor-pointer"
                  @onIconClick="removeRow(session.frontID)"
                />
              </b-col>

              <hr class="w-100 my-2">
            </template>
          </b-row>
        </draggable>

        <b-button
          variant="outline-primary"
          @click.prevent="addSession"
        >
          Add {{ sessionSemester(1) }}
        </b-button>

        <!-- buttons -->
        <b-row>

          <b-col cols="12">
            <b-button
              variant="primary"
              class="mt-2 mr-1"
              @click="handleForm"
            >
              Save changes
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              :to="{ name: 'admin-program'}"
              @click.prevent="resetForm"
            >
              Cancel
            </b-button>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormCheckbox, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import draggable from 'vuedraggable'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'

extend('positive', value => {
  if (value >= 0) {
    return true
  }
  return 'This value must be a positive number'
})

export default {
  name: 'ProgramTabSessions',
  components: {
    draggable,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    flatPickr,

    SquareIcon,
  },
  props: {
    sessions: {
      type: Array,
      default: () => {},
    },
    enableDates: {
      type: String,
      default: '',
    },
    programData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    fieldsCount: 1,
  }),
  computed: {
    activeDates() {
      const activeDates = []
      const startDate = this.enableDates ? this.enableDates.split(' ')[0] : ''
      const endDate = this.enableDates ? this.enableDates.split(' ')[2] : ''

      activeDates.push(
        {
          from: startDate,
          to: endDate === undefined ? startDate : endDate,
        },
      )
      return activeDates
    },
  },
  watch: {
    sessions(val) {
      if (!val) return

      this.fieldsCount = this.sessions.length
    },
  },
  methods: {
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.userRules.validate().then(success => {
          if (success) {
            resolve(true)

            this.sessions.forEach(session => {
              if (session.date) {
                const [dateStart, , dateEnd] = (session.date).split(' ')

                // eslint-disable-next-line
                session.start_date = dateStart
                // eslint-disable-next-line
                session.end_date = dateEnd === undefined ? dateStart : dateEnd
              }
            })
            this.$emit('nextTab')
          } else {
            reject()
          }
        })
      })
    },
    addSession() {
      this.fieldsCount += 1
      this.sessions.push({
        frontID: this.fieldsCount,
        name: '',
        date: '',
        price: '',
        registration: false,
        delete: false,
        newSession: true,
        divisions: [],
      })
    },
    removeRow(rowID) {
      const curItem = this.sessions.findIndex(item => item.frontID === rowID)
      this.sessions[curItem].delete = true

      if (this.sessions[curItem].newSession) {
        this.sessions.splice(curItem, 1)
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  hr {
    border-top-width: 2px;
  }

  .media-item {
    position: relative;

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
</style>
