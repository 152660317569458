<template>
  <b-card
    class="step-item"
  >
    <div class="w-100 d-flex align-items-center justify-content-between">
      <div
        class="requirement-color"
        :style="{backgroundColor: requirementColor}"
      />
      <h5 class="mb-0 d-flex align-items-center">
        <img
          src="@/assets/images/svg/more-vertical.svg"
          class="mr-1"
          alt=""
        >
        <router-link
          :to="{ name: 'admin-form-edit', params: { id: step.form_id } }"
          target="_blank"
        >
          {{ step.name }}
        </router-link>
      </h5>
      <b-dropdown
        variant="link"
        toggle-class="mx-auto p-0"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >
        <template #button-content>
          <feather-icon
            icon="MoreHorizontalIcon"
            size="16"
            class="align-middle text-primary"
          />
        </template>

        <b-dropdown-item
          @click="editStep"
        >
          Edit
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canReadApplicationTriggers"
          @click="showSetupStepEventModal"
        >
          Set up event
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="step.has_results"
          variant="danger"
          @click="deleteStep"
        >
          <span>Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BCard,
    BDropdown,
    BDropdownItem,
  },
  props: {
    status: {
      type: Object,
      default: () => {},
    },
    step: {
      type: Object,
      default: () => {},
    },
    canReadApplicationTriggers: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    requirementColor() {
      if (this.step.requirement_option === 'Not Required Step') return '#26C6E9'
      if (this.step.requirement_option === 'Required Step') return '#E44243'
      if (this.step.requirement_option === 'Required To Continue') return '#B465F2'
      if (this.step.requirement_option === 'Requires Approval') return '#8BE11E'
      if (this.step.requirement_option === 'Requires Approval To Continue') return '#FF7D34'
      return '#000'
    },
  },
  methods: {
    editStep() {
      store.commit('app-program/SET_SELECTED_STATUS', this.status)
      store.commit('app-program/SET_SELECTED_STEP', this.step)
      store.commit('app-program/SET_STEP_MODAL_TYPE', 'edit')
      this.$bvModal.show('edit-step-modal')
    },
    showSetupStepEventModal() {
      store.commit('app-program/SET_SELECTED_STATUS', this.status)
      store.commit('app-program/SET_SELECTED_STEP', this.step)
      this.$bvModal.show('setup-step-events')
    },
    async deleteStep() {
      store.commit('app-program/DELETE_STEP', {
        statusId: this.status.id,
        stepId: this.step.id,
      })
      store.commit('app-program/SET_HAS_UNSAVED_CHANGES', true)
    },
  },
}
</script>

<style lang="scss" scoped>
.step-item {
  background: #FFFFFF;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px;
  margin-bottom: 1rem;
}
.requirement-color {
  position: absolute;
  left: 0;
  height: 80%;
  width: 4px;
  border-radius: 4px;
  background: #000;
}
</style>
