<template>
  <div>
    <b-card
      no-body
    >
      <!-- Header-->
      <div
        class="header d-flex align-items-center justify-content-between my-2"
      >

        <div class="d-flex align-items-center">
          <h2 class="font-weight-normal mr-5">
            Registration Form
          </h2>
          <template v-if="statusesList.length > 0">
            <label
              class="mr-1 mb-0 col-form-label"
            >Active
            </label>
            <b-form-checkbox
              switch
              :checked="registrationFormData.activeStatus"
              class="d-inline-block ml-50 mr-1"
              @input="updateRegistrationFormStatus"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </template>
        </div>

        <div>
          <template>
            <div
              id="editing_status__handler"
              class="editing_status__handler"
            >
              Editing Settings
              <feather-icon
                size="21"
                icon="EditIcon"
                class="cursor-pointer text-primary ml-1"
                @click="$bvModal.show('editing-settings-modal')"
              />
            </div>
            <b-popover
              target="editing_status__handler"
              triggers="hover"
              placement="topright"
              boundary="window"
            >
              <div class="d-flex align-items-center">
                <feather-icon
                  icon="InfoIcon"
                  size="20"
                  class="text-primary flex-shrink-0"
                />
                <span class="ml-1">
                  Permission settings for editing the application by the user
                </span>
              </div>
            </b-popover>
          </template>
          <template>
            <div
              id="failed_payment_status__handler"
              class="failed_payment_status__handler"
            >
              Failed Payment Behavior Settings
              <feather-icon
                size="21"
                icon="EditIcon"
                class="cursor-pointer text-primary ml-1"
                @click="$bvModal.show('failed-payment-behavior-settings-modal')"
              />
            </div>
            <b-popover
              target="failed_payment_status__handler"
              triggers="hover"
              placement="topright"
              boundary="window"
            >
              <div class="d-flex align-items-center">
                <feather-icon
                  icon="InfoIcon"
                  size="20"
                  class="text-primary flex-shrink-0"
                />
                <span class="ml-1">
                  settings for application statuses behavior on payment failed flag
                </span>
              </div>
            </b-popover>
          </template>
        </div>

        <div>
          <b-button
            variant="primary"
            @click="showAddNewStatus"
          >
            Add Status
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
          </b-button>
          <b-dropdown
            class="ml-2 mr-4"
            toggle-class="p-0"
            variant="primary"
            dropleft
            no-caret
            right
          >
            <template
              #button-content
            >
              <feather-icon
                icon="GridIcon"
                size="21"
                class="m-50"
              />
            </template>
            <b-dropdown-item
              v-b-modal="'add-condition-'+ type"
            >
              Add Condition
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal="'add-cross-program-condition-'+ type"
            >
              Add Cross-Program Condition
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            variant="primary"
            class="ml-5"
            :disabled="isLoading || !hasUnsavedChanges"
            @click="saveFormData"
          >
            Save changes
            <feather-icon
              icon="SaveIcon"
              size="16"
              class="text-white"
            />
          </b-button>
        </div>
      </div>

      <spinner
        v-if="isLoading"
        label="Loading"
        small
      />

      <draggable
        v-else
        v-model="statusesList"
        class="statuses-list"
        @end="finishMove"
      >
        <template v-for="(status, index) in statusesList">
          <div
            v-if="status.divider"
            :key="index"
            class="status-divider"
          >
            <div
              id="status-divider__handler"
              class="status-divider__handler"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
                class="text-white"
              />
              <feather-icon
                icon="ChevronRightIcon"
                size="12"
                class="text-white"
              />
            </div>
            <b-popover
              target="status-divider__handler"
              triggers="hover"
              placement="topright"
              boundary="window"
            >
              <div class="d-flex align-items-center">
                <feather-icon
                  icon="InfoIcon"
                  size="20"
                  class="text-primary flex-shrink-0"
                />
                <span class="ml-1">
                  Slide this to select when Applicant is accepted to the program
                </span>
              </div>
            </b-popover>
          </div>
          <status-card
            v-else
            :key="status.order"
            :status="status"
          />
        </template>

        <div
          v-if="isNewStatusAdding"
          :key="isNewStatusAdding"
          class="new-status"
        >
          <b-form-input
            ref="newStatusName"
            v-model="newStatusName"
            v-click-outside="addOrResetNewSatus"
            placeholder="Status Name"
            @keyup.enter="addNewStatus"
          />
        </div>
      </draggable>

    </b-card>

    <editing-settings-modal
      :steps-list="stepsList"
    />

    <failed-payment-behavior-settings-modal
      :statuses-list="statusesListWithoutDivider"
    />

    <edit-step-modal />

    <setup-status-events-modal />

    <setup-step-events-modal />

    <setup-status-tags-modal />

    <applications-for-tag-auto-assign-modal
      :tag-ids-list="tagIdsListForAutoAssign"
      :applications-list="applicationsForTagAutoAssign"
    />

    <add-condition-modal
      :type="type"
      :condition-data="conditionsArr"
      :fields-data="fieldsForConditions"
      :steps-data="stepsOptions"
      @saveConditions="saveConditions"
    />

    <add-cross-program-condition-modal
      :type="type"
      :condition-data="crossProgramsConditionsArr"
      :fields-data="fieldsForConditions"
      :steps-data="stepsOptions"
      @saveConditions="saveCrossProgramsConditions"
    />

  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import vClickOutside from 'v-click-outside'

import { mapGetters } from 'vuex'
import {
  BCard,
  BButton,
  BFormInput,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BPopover,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import store from '@/store'

import Spinner from '@/views/components/table-spinner/TableSpinner.vue'
import StatusCard from '@/views/admin/program/program-registration-statuses/status-card/StatusCard.vue'
import EditStepModal from '@/views/admin/program/program-registration-statuses/modals/EditStepModal.vue'
import SetupStatusEventsModal from '@/views/admin/program/program-registration-statuses/modals/SetupStatusEventsModal.vue'
import SetupStepEventsModal from '@/views/admin/program/program-registration-statuses/modals/SetupStepEventsModal.vue'
import SetupStatusTagsModal from '@/views/admin/program/program-registration-statuses/modals/SetupStatusTagsModal.vue'
import AddConditionModal from '@/views/components/form-builder/modals/AddConditionModal.vue'
import EditingSettingsModal from '@/views/admin/program/program-registration-statuses/modals/EditingSettingsModal.vue'
import ApplicationsForTagAutoAssignModal
  from '@/views/components/applications-for-tag-auto-asign-modal/ApplicationsForTagAutoAssignModal.vue'
import AddCrossProgramConditionModal from '@/views/components/form-builder/modals/AddCrossProgramConditionModal.vue'
import FailedPaymentBehaviorSettingsModal
  from '@/views/admin/program/program-registration-statuses/modals/FailedPaymentBehaviorSettingsModal.vue'

Vue.use(vClickOutside)
export default {
  components: {
    FailedPaymentBehaviorSettingsModal,
    ApplicationsForTagAutoAssignModal,
    BCard,
    BButton,
    BFormInput,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BPopover,

    draggable,

    Spinner,
    StatusCard,
    EditStepModal,
    EditingSettingsModal,
    AddConditionModal,
    SetupStatusEventsModal,
    SetupStepEventsModal,
    SetupStatusTagsModal,
    AddCrossProgramConditionModal,
  },
  props: {
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newStatusName: '',
      isNewStatusAdding: false,
      stepsCount: 1,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'app-program/getIsLoading',
      programId: 'app-program/getProgramId',
      registrationFormData: 'app-program/getRegistrationFormData',
      stepsOptions: 'app-program/getStepsOptions',
      stepsList: 'app-program/getProgramStepsList',
      statusesListWithoutDivider: 'app-program/getStatusesListWithoutDivider',
      formsIds: 'app-program/getStepsFormsIDs',
      usePC: 'app-program/getUsePC',
      hidePB: 'app-program/getHidePB',
      conditionsArr: 'app-program/getConditionsArr',
      crossProgramsConditionsArr: 'app-program/getCrossProgramsConditionsArr',
      fieldsForConditions: 'app-program/getFieldsForConditions',
      groupTypeOptions: 'app-program/getGroupTypeOptions',
      type: 'app-program/getType',
      applicationsForTagAutoAssign: 'app-program/getApplicationsForTagAutoAssign',
      tagIdsListForAutoAssign: 'app-program/getTagIdsListForAutoAssign',
    }),
    statusesList: {
      get() {
        return store.getters['app-program/getStatusesList']
      },
      set(val) {
        store.commit('app-program/SET_STATUSES_LIST', val)
      },
    },
    hasUnsavedChanges: {
      get() {
        return store.getters['app-program/getHasUnsavedChanges']
      },
      set(val) {
        store.commit('app-program/SET_HAS_UNSAVED_CHANGES', val)
      },
    },
  },
  watch: {
    async programId() {
      await this.setRegistrationFormByType()
    },
    stepsList: {
      deep: true,
      async handler(val) {
        if (val.length > 0) {
          this.stepsCount = this.stepsList.length
          await this.prepareFieldsBySteps()
        }
      },
    },
    applicationsForTagAutoAssign(val) {
      if (val.length > 0) {
        this.$bvModal.show('applications-for-tag-auto-assign')
      }
    },
  },
  async mounted() {
    await this.setRegistrationFormByType()
  },
  methods: {
    async setRegistrationFormByType() {
      this.hasUnsavedChanges = false
      store.commit('app-program/SET_FORM_TYPE', this.formType)
      store.commit('app-program/SET_IS_LOADING', true)
      store.commit('app-program/RESET_STATUSES_LIST')
      store.commit('app-program/RESET_CONDITIONS_LIST')
      store.commit('app-program/RESET_CROSS_PROGRAMS_CONDITIONS_LIST')
      store.commit('app-program/RESET_USE_PC')
      store.commit('app-program/RESET_HIDE_PB')

      if (this.programId) {
        await this.prepareGroupTypes()
        await this.prepareRegistrationForm()
      }

      store.commit('app-program/SET_IS_LOADING', false)
    },
    showAddNewStatus() {
      this.isNewStatusAdding = true
      this.$nextTick(() => {
        this.$refs.newStatusName.focus()
      })
    },
    resetNewSatus() {
      this.newStatusName = ''
      this.isNewStatusAdding = false
    },
    async addNewStatus() {
      this.statusesList.push({
        id: uuidv4(),
        position: this.statusesList.length,
        name: this.newStatusName,
        steps: [],
      })
      this.resetNewSatus()
      store.commit('app-program/UPDATE_STATUSES_POSITIONS_AND_APPLY_GROUPS')
      this.hasUnsavedChanges = true
    },
    addOrResetNewSatus() {
      if (this.newStatusName !== '') {
        this.addNewStatus()
      } else {
        this.resetNewSatus()
      }
    },
    async prepareGroupTypes() {
      await store.dispatch('app-program/prepareGroupTypes')
    },
    async prepareRegistrationForm() {
      await store.dispatch('app-program/fetchRegistrationForm')
    },
    async prepareFieldsBySteps() {
      await store.dispatch('app-program/prepareFieldsBySteps')
    },
    async updateRegistrationFormStatus(val) {
      store.commit('app-program/SET_REGISTRATION_FORM_STATUS', val)
      this.hasUnsavedChanges = true
    },
    async saveConditions(val) {
      store.commit('app-program/SET_CONDITIONS_ARR', val)
      this.hasUnsavedChanges = true
    },
    async saveCrossProgramsConditions(val) {
      store.commit('app-program/SET_CROSS_PROGRAMS_CONDITIONS_ARR', val)
      this.hasUnsavedChanges = true
    },
    async finishMove() {
      store.commit('app-program/UPDATE_STATUSES_POSITIONS_AND_APPLY_GROUPS')
      this.hasUnsavedChanges = true
    },
    async saveFormData() {
      await store.dispatch('app-program/saveRegistrationForm', this)
    },
  },
}
</script>

<style lang="scss" scopped>
.statuses-list {
  position: relative;
  border-top: 1px solid #ebe9f1;
  padding-top: 30px;
  padding-left: 18px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-height: 400px;
}
.status-divider {
  position: relative;
  align-self: stretch;
  flex-basis: 36px;
  flex-shrink: 0;
  width: 36px;
  // background: #7367F0;
  &::after {
    content: '';
    cursor: move;
    display: block;
    width: 2px;
    height: 100%;
    background: #7367F0;
    position: absolute;
    left: 17px;
  }
  &__handler {
    cursor: move;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    width: 36px;
    height: 18px;
    background: #7367F0;
    // clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    // background: linear-gradient(to bottom right, #fff 0%, #fff 50%, #a48d01 50%, #a48d01 100%);
  }
}
.new-status {
  align-self: start;
  background: #FBFBFC;
  border: 1px solid #D8D6DE;
  border-radius: 8px;
  width: 270px;
  flex-basis: 270px;
  flex-shrink: 0;
  margin: 0 10px;
}
</style>
