<template>
  <b-modal
    id="edit-step-modal"
    ref="edit-step-modal"
    centered
    :title="title"
    :ok-title="okTitle"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="setModalData"
    @ok="handleForm"
    @hidden="initStepData"
  >
    <validation-observer
      ref="editStepObserver"
      tag="form"
    >
      <b-row>
        <!-- Step Name -->
        <b-col
          cols="12"
        >
          <b-form-group
            label="Step name"
          >
            <validation-provider
              #default="{ errors }"
              name="Step Name"
              rules="required"
            >
              <b-form-input
                v-model="step.name"
                placeholder="Step Name"
                :state="errors.length > 0 ? false : null"
                @input="updateStepKey"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Step Key -->
        <b-col
          cols="12"
        >
          <b-form-group
            label="Step Key"
          >
            <validation-provider
              #default="{ errors }"
              name="Step Key"
              rules="required"
            >
              <b-form-input
                v-model="step.key"
                placeholder="step_key"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label="Select Form"
          >
            <validation-provider
              #default="{ errors }"
              name="Step Form"
              rules="required"
            >
              <v-select
                v-model="step.form_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="formsList"
                :placeholder="'Select Form'"
                label="name"
                :reduce="option => option.id"
                :clearable="false"
                :class="{'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label="Requirement"
          >
            <validation-provider
              #default="{ errors }"
              name="Requirement"
              rules="required"
            >
              <v-select
                v-model="step.requirement_option"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="requirementOptions"
                :placeholder="'Select Requirement'"
                :clearable="false"
                :class="{'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <!-- Has Payment Contract -->
          <b-form-group
            v-if="type == 'Camper'"
            label="Has Payment Contract"
            class="text-break"
          >
            <b-form-checkbox
              v-model="step.has_payment_contract"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col
          v-if="step.has_payment_contract"
          cols="12"
        >
          <!-- Display Payment Contract On Top -->
          <b-form-group
            v-if="type == 'Camper'"
            label="Display Payment Contract On Top"
            class="text-break"
          >
            <b-form-checkbox
              v-model="step.display_pc_on_top"
              :disabled="!step.has_payment_contract"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon"/>
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon"/>
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <!-- Hide Price Breakdown -->
          <b-form-group
            v-if="type == 'Camper'"
            label="Hide Price Breakdown"
            class="text-break"
          >
            <b-form-checkbox
              v-model="step.hide_price_breakdown"
              :disabled="!step.has_payment_contract"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <!-- Use Form Pre-fill -->
          <b-form-group
            label="Use Form Pre-fill"
            class="text-break"
          >
            <b-form-checkbox
              v-model="step.use_form_prefill"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'

import {
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BFormSelect,

    vSelect,
  },
  data() {
    return {
      step: {},
      required,
    }
  },
  computed: {
    ...mapGetters({
      stepModalType: 'app-program/getStepModalType',
      selectedStatus: 'app-program/getSelectedStatus',
      selectedStep: 'app-program/getSelectedStep',
      formsList: 'app-program/getFormsList',
      requirementOptions: 'app-program/getRequirementOptions',
      isLast: 'app-program/getIsLastStatusInArray',
      type: 'app-program/getType',
    }),
    title() {
      return this.stepModalType === 'create' ? 'Step Creation' : 'Edit Step'
    },
    okTitle() {
      return this.stepModalType === 'create' ? 'Add' : 'Ok'
    },
  },
  methods: {
    setModalData() {
      if (this.stepModalType === 'create') {
        this.initStepData()
      } else {
        this.editStepData()
      }
    },
    initStepData() {
      this.step = {
        id: uuidv4(),
        name: '',
        key: '',
        form_id: null,
        requirement_option: null,
        is_last: this.isLast,
        has_payment_contract: false,
        display_pc_on_top: true,
        hide_price_breakdown: false,
        use_form_prefill: false,
      }
    },
    editStepData() {
      this.step = { ...this.selectedStep }
    },
    updateStepKey(e) {
      this.step.key = e.toLowerCase().replace(/\s+/g, '_')
    },
    handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.editStepObserver.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.stepModalType === 'create') {
              this.addNewStep()
            } else {
              this.updateStep()
            }
          } else {
            reject()
          }
        })
      })
    },
    async addNewStep() {
      store.commit('app-program/ADD_NEW_STEP', this.step)
      store.commit('app-program/UPDATE_PC_STEP', this.step)
      store.commit('app-program/SET_HAS_UNSAVED_CHANGES', true)
      this.$bvModal.hide('edit-step-modal')
    },
    async updateStep() {
      store.commit('app-program/UPDATE_STEP', this.step)
      store.commit('app-program/UPDATE_PC_STEP', this.step)
      store.commit('app-program/SET_HAS_UNSAVED_CHANGES', true)
      this.$bvModal.hide('edit-step-modal')
    },
  },
}
</script>

<style>

</style>
